import React, { useEffect, useState } from "react";
import "../App.css";
import "../theme.css";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { post } from "../helpers/api_helper";
import { POST_MINISTER_CONTACT_FORM } from "../helpers/url_helper";


const ContactCard = () => {

  const [civil_status, setCivil_status] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [object, setobject] = useState("");
  const [message, setMessage] = useState("");
  const [requestError, setRequestError] = useState("");
  const [sucess, setSucess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestError(null);
    setSucess(null)
    if (email === "" || civil_status === "" || first_name === "" || last_name === "" || telephone === "" || object === "" || message === "") {
      setRequestError("* Veillez remplir tout les champs !")
    } else {

      try {
        const response = await post(POST_MINISTER_CONTACT_FORM, { civil_status, first_name, last_name, email, telephone, object, message });
        console.log("********* Contact Response***** ", response);
        setSucess("Votre message a bien été envoyé, merci !");
        setfirst_name("");
        setLast_name("");
        setTelephone("");
        setCivil_status("");
        setEmail("");
        setobject("");
        setMessage("");
      } catch (error) {
        setRequestError("L'envoie du message a échoué veillez réessayer à nouveau !")
        console.log("Abonnement error ", error);
      }
    }
  }

  useEffect(()=>{
    setRequestError(null)
  }, [first_name, last_name, email, message, object, civil_status])

  return (

    <div className="container pt-11">
      <div className="row">
        <div className="offset-lg-1 offset-md-0 col-lg-10 col-md-12">
          <Form onSubmit={handleSubmit} className="box-contact">
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="formGridText" className="mb-6">
                  <Form.Label>Civilité *</Form.Label>
                  <Form.Select className="w-100" value={civil_status} onChange={(e) => { setCivil_status(e.target.value) }}>
                    <option value="" className="text-white"></option>
                    <option value={"Mlle"} className="text-white">Mlle.</option>
                    <option value="Mr">Mr.</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Objet*</Form.Label>
                  <Form.Control type="text" placeholder="objet" className="w-100" value={object} onChange={(e) => { setobject(e.target.value) }} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Nom*</Form.Label>
                  <Form.Control type="text" placeholder="Nom" className="w-100" value={last_name} onChange={(e) => { setLast_name(e.target.value) }} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Prénom*</Form.Label>
                  <Form.Control type="text" placeholder="Prenom" className="w-100" value={first_name} onChange={(e) => { setfirst_name(e.target.value) }} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control type="email" placeholder="Email" className="w-100" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formGridDate" className="mb-6">
                  <Form.Label>Téléphone*</Form.Label>
                  <Form.Control type="phone" placeholder="Téléphone" className="w-100" value={telephone} onChange={(e) => { setTelephone(e.target.value) }} />
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Group controlId="formGridDate">
                  <Form.Label>Message*</Form.Label>
                  <Form.Control as="textarea" placeholder="Votre message" className="w-100" style={{ height: "200px" }} value={message} onChange={(e) => { setMessage(e.target.value) }} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="text-end">
                <Form.Group controlId="formGridSubmit">
                  <Button type="submit" className="w-100">
                    Envoyer
                  </Button>
                  {requestError && <p className="text-white text-center pt-3 mb-0">{requestError}</p>}
                  {sucess && <p className="text-white text-center pt-3 mb-0">{sucess}</p>}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>

  );
}

export default ContactCard;
