import { useLocation } from "react-router-dom";
import { Facebook, Twitter, Linkedin } from "react-bootstrap-icons";

import { formatSimpleDate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";
import documentImage from "../assets/images/document.png";

export const AboutUsTitle =
  "Le Ministère des Infrastructures et du Désenclavement est Chargé de la Conception, de la Coordination, de la mise en Œuvre et du Suivi de la Politique du Gouvernement en matière d’Infrastructures, des Transports de Surface et de Désenclavement, en collaboration avec les Départements Ministériels concernés";

export const RenderImage = (dataImage) => {
  let location = useLocation();
  let path = location.pathname;

  if (
    path.startsWith("/biographie") ||
    path.startsWith("/etat-des-lieux") ||
    path.startsWith("/reformes") ||
    path.startsWith("/archive-du-ministere") ||
    path.startsWith("/actualites") ||
    path.startsWith("/rapport-activites") ||
    path.startsWith("/start-up") ||
    path.startsWith("/formation") ||
    path.startsWith("/promotion-economique-numerique") ||
    path.startsWith("/sensibilisation")
  ) {
    return (
      <img
        src={dataImage?.original_url || documentImage}
        className="img-fluid w-100"
        style={{
          width: "100%",
          maxHeight: "50vh",
          objectFit: "cover",
        }}
        alt="Project"
      />
    );
  }
  return null;
};

export const RenderPeridoBlock = (data) => {
  let location = useLocation();
  let path = location.pathname;

  if (path.startsWith("/rapport-activites")) {
    return (
      <div>
        Période :{" "}
        <h6 className="pb-2 d-inline">{formatSimpleDate(data?.start_date)}</h6>
      </div>
    );
  }
};

export const RenderShareBlock = () => {
  let location = useLocation();
  let path = location.pathname;

  if (
    path.startsWith("/visions") ||
    path.startsWith("/objectifs") ||
    path.startsWith("/missions-du-ministere") ||
    path.startsWith("/directions-sous-tutelles")
  ) {
    return (
      <div className="share text-center py-4 d-flex align-items-center justify-content-center">
        <h3 className="mr-4 mt-2">
          Partagez cette page sur vos réseaux sociaux :
        </h3>
        <ul>
          <li>
            <a href="#">
              <Facebook style={{ color: "#1877F2" }} />
            </a>
          </li>
          <li>
            <a href="#">
              <Twitter style={{ color: "#1DA1F2" }} />
            </a>
          </li>
          <li>
            <a href="#">
              <Linkedin style={{ color: "#0077b5" }} />
            </a>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};

export const RenderPublicationBlock = (data) => {
  let location = useLocation();
  let path = location.pathname;

  if (
    path.startsWith("/etat-des-lieux") ||
    path.startsWith("/reformes") ||
    path.startsWith("/organisation-et-fonctionnement") ||
    path.startsWith("/plan-action-2024") ||
    path.startsWith("/rapport-activites")
  ) {
    return (
      <div>
        Publié en{" "}
        <h6 className="pb-2 d-inline">{formatSimpleDate(data?.created_at)}</h6>
      </div>
    );
  } else if (path.startsWith("/start-up")) {
    return (
      <div>
        Crée le{" "}
        <h6 className="pb-2 d-inline">{formatSimpleDate(data?.created_at)}</h6>
      </div>
    );
  }
};

export const RenderText = () => {
  let location = useLocation();
  let path = location.pathname;

  if (
    path.match("/visions") ||
    path.match("/missions-du-ministere") ||
    path.match("/objectifs")
  ) {
    return AboutUsTitle;
  }
  return null;
};

export const RenderAuthorBlock = (data) => {
  let location = useLocation();
  let path = location.pathname;

  if (
    path.startsWith("/etat-des-lieux") ||
    path.startsWith("/reformes") ||
    path.startsWith("/organisation-et-fonctionnement") ||
    path.startsWith("/plan-action-2024") ||
    path.startsWith("/start-up") ||
    path.startsWith("/rapport-activites")
  ) {
    return (
      <div>
        <h6 className="pb-2 d-inline">
          <span>Par:</span> {data?.author}
        </h6>
      </div>
    );
  }
};

export const RenderSecteurBlock = (data) => {
  let location = useLocation();
  let path = location.pathname;

  if (path.startsWith("/rapport-activites")) {
    return (
      <div>
        <h6 className="pb-2 d-inline">
          <span>Seteur:</span> {data?.secteur_activite}
        </h6>
      </div>
    );
  }
};

export const RenderButtonTitle = () => {
  let location = useLocation();
  let path = location.pathname;

  if (path.startsWith("/etat-des-lieux")) {
    return "LIRE";
  }
  if (path.startsWith("/reformes")) {
    return "LIRE";
  }
  return "LIRE PLUS";
};

export const RenderSearchTitle = () => {
  let location = useLocation();
  let path = location.pathname;

  if (path.startsWith("/etat-des-lieux")) {
    return "RECHERCHER UN TEXTE";
  }
  if (path.startsWith("/projet-encour-de-realisation")) {
    return "RECHERCHER UN PROJET";
  }
  if (path.startsWith("/rapport-activites")) {
    return "RECHERCHER UN RAPPORT";
  }
  if (path.startsWith("/opportunites")) {
    return "RECHERCHER UN OPPORTUNITE";
  }
  if (path.startsWith("/start-up")) {
    return "RECHERCHER UN STARTUP";
  }
  if (path.startsWith("/formation")) {
    return "RECHERCHER UNE FORMATION";
  }
  if (path.startsWith("/agenda")) {
    return "RECHERCHER UN ÉVÈNEMENT";
  }
  return "RECHERCHER UN DOCUMENT";
};
