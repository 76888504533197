import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ExtraCard from "../components/ExtraCard";

import { juriCategoryDocumentData } from "../redux/actions/action";

const Arrete = () => {
  let arrete = useSelector((state) => state.juriCategoryDocument);
  const dispatch = useDispatch();

  const [arreteData, setArreteDatas] = useState();

  let arretes = arrete?.juriCategoryDocuments?.data?.document;
  let loading = arrete?.loading;

  console.log("laws: ", arrete?.document);

  useEffect(() => {
    if(arretes){
      setData(arretes);
    }
  }, [arretes]); 
  
  const setData = (data) => {
    setArreteDatas(data);
  };

  const onDataFilter = (doc, keyWord,  starthear, endhear) => {
    let filteredText = arretes;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
      const timestampT2 = t2.getTime();
      console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
    }

    setArreteDatas(filteredText);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(juriCategoryDocumentData("aretes"));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Liste des Arrêtés" onFilter={onDataFilter} />
      <ExtraCard data={arreteData} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Arrete;
