import React, { useState, useEffect } from "react";
import { Facebook, Twitter, Linkedin, Envelope } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import Pagination from "./Pagination";
import {
  formatSimpleDate,
  splitList,
  truncate,
} from "../helpers/global_helper";
import document from "../assets/images/documentation.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../App.css";
import "../theme.css";

const ExtraCard = ({ data, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10));
    }
  }, [data]);

  let renderPagination = () => {
    if (
      path.startsWith("/etat-des-lieux") ||
      path.startsWith("/lois") ||
      path.startsWith("/decrets") ||
      path.startsWith("/arretes") ||
      path.startsWith("/arretes") ||
      path.startsWith("/observatoires") ||
      path.startsWith("/reformes") ||
      path.startsWith("/platformes") ||
      path.startsWith("/organisation-et-fonctionnement") ||
      path.startsWith("/plan-strategique-2030") ||
      path.startsWith("/plan-action-2024") ||
      path.startsWith("/projets-realises") ||
      path.startsWith("/rapport-activites")
    ) {
      return (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      );
    }
  };

  const renderSkeleton = () => {
    return (
      <div className="col-lg-12 mb-4">
        <div className="current-box">
          <div className="row">
            <div className="col-5 list-info d-flex align-items-center">
              <Skeleton width={200} height={200} />
            </div>
            <div className="col-7">
              <div className="current-text">
                <Skeleton count={3} />
              </div>
              <div className="current-share d-flex align-items-center justify-content-between">
                <div className="share d-flex align-items-center">
                  <Skeleton width={100} />
                </div>
                <div className="action">
                  <Skeleton width={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container pt-6">
      <div className="box row">
        {loading &&
          Array.from({ length: 4 }).map((_, index) => (
            <React.Fragment key={index}>{renderSkeleton()}</React.Fragment>
          ))}

        {!loading && data?.length === 0 ? (
          <p className="text-center pt-11">
            Aucune donnée disponible pour le moment. Veuillez réessayer plus
          </p>
        ) : (
          splitedData[currentPage - 1]?.map((elmt, index) => {
            let documentUrl = elmt?.media?.find(
              (media) =>
                media.mime_type === "application/pdf" ||
                media.mime_type === "application/msword" ||
                media.mime_type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            );

            const renderBlock = () => {
              if (
                !(
                  path.startsWith("/lois") ||
                  path.startsWith("/decrets") ||
                  path.startsWith("/arretes")
                )
              ) {
                return (
                  <div className="author">
                    <span>Par : </span> {elmt?.author}
                  </div>
                );
              }
            };

            return (
              <div className="col-lg-12 mb-4" key={index}>
                <div className="current-box">
                  <div className="row">
                    <div className="col-5 list-info d-flex align-items-center">
                      <div className="img">
                        <img
                          src={document}
                          style={{ maxWidth: "50%" }}
                          className="img-fluid"
                          alt="Document"
                        />
                      </div>
                      <div className="infos-doc">
                        <div className="date">
                          <span>
                            Publié en {formatSimpleDate(elmt?.created_at)}
                          </span>
                        </div>
                        {renderBlock()}
                        <div className="size">
                          {elmt?.size} <span> ko</span>
                        </div>
                        <div className="views">
                          {elmt?.views_count} <span> Lectures</span>
                        </div>
                        <div className="download">
                          {elmt?.downloads_count} <span> Téléchargements</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="current-text">
                        <h3>{elmt?.title || elmt?.name}</h3>
                        <p className="mt-3">{truncate(elmt?.content, 80)}</p>
                      </div>
                      <div className="current-share d-flex align-items-center justify-content-between">
                        <div className="share d-flex align-items-center">
                          <span className="mr-3">Partager sur</span>
                          <ul>
                            <li>
                              <a href="#">
                                <Facebook style={{ color: "#1877F2" }} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <Twitter style={{ color: "#1DA1F2" }} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <Linkedin style={{ color: "#0077b5" }} />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <Envelope />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="action">
                          <a
                            className="mr-6"
                            href={documentUrl?.original_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Lire
                          </a>
                          <a href={documentUrl?.original_url} download>
                            Télécharger
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      {data?.length > 0 && <div className="row">{renderPagination()}</div>}
    </div>
  );
};

export default ExtraCard;
