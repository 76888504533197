import React from "react";

import "../App.css";
import "../theme.css";

import SimpleCard from "./SimpleCard";

const HomeCard = ({ title, data, page, loading }) => {
  return (
    <section className="projects">
      <div className="container">
        <h2 className="pt-5">{title}</h2>
        <SimpleCard data={data} page={page} loading={loading} />
      </div>
    </section>
  );
};

export default HomeCard;
