import { createSlice } from "@reduxjs/toolkit";

import { repportData, repportInfo } from "../actions/action";

const repportSlice = createSlice({
  name: "repports",
  initialState: {
    loading: false,
    repport: {},
    repports: {},
  },
  extraReducers: (builder) => {
    builder.addCase(repportData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(repportData.fulfilled, (state, action) => {
      state.loading = false;
      state.repports = action.payload;
      state.error = "";
    });
    builder.addCase(repportData.rejected, (state, action) => {
      state.loading = false;
      state.repports = [];
      state.error = action.error.message;
    });

    builder.addCase(repportInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(repportInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.repport = action.payload;
      state.error = "";
    });
    builder.addCase(repportInfo.rejected, (state, action) => {
      state.loading = false;
      state.repport = {};
      state.error = action.error.message;
    });
  },
});

export default repportSlice.reducer;
