import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CardCurrent from "./SearchCurrent";
import CardObservatory from "./SearchObservatory";
import CardSearchNum from "./CardSearchNum";

import { RenderSearchTitle } from "../utils/const";

import EtatLieuImage from "../assets/images/etats_des_lieux/image_etats_des_lieux.jpg";
import JuridiqueImage from "../assets/images/cadres_juridiques_et_institutionnels/cadres_juridique.jpeg";
import ReformImage from "../assets/images/reformes/reforme.jpg";
import EcosystemTchadImage from "../assets/images/ecosysteme_du_tchad/ecosysteme_tchad.jpg";
import PlateformeImage from "../assets/images/plateformes/plateforme.jpg";
import MinisterImage from "../assets/images/ministre/ministere.jpg";
import ProjectImage from "../assets/images/projets/projet.jpg";
import ActiualiteImage from "../assets/images/communications/actualite.jpg";
import RapportActiviteImage from "../assets/images/communications/rapport_activite.jpeg";
import DocumentationImage from "../assets/images/communications/documentation.jpeg";
import OpportunityImage from "../assets/images/communications/opportunite.jpeg";
import EconomieNumeriqueImage from "../assets/images/economie_numerique/economie_numerique.jpeg";
import OrganisationFonctionImage from "../assets/images/organisation_fonctionnement/organisation_fonctionnement.jpg";
import AgendaImage from "../assets/images/agenda/agenda.jpeg";
import ContactImage from "../assets/images/contact/contact.jpeg";
import AboutUsImage from "../assets/images/a_propos/a_propos.jpg";
import CurrentImage from "../assets/images/currentimage.jpg";

import "../App.css";
import "../theme.css";
import SeachTwoTherm from "./SearchTwoTherm";

const Banner = ({ title, onFilter }) => {
  const [imageSrc, setImageSrc] = useState(CurrentImage);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (title === "Etats des lieux") {
      setImageSrc(EtatLieuImage);
    }
    if (title === "Liste des lois") {
      setImageSrc(JuridiqueImage);
    }
    if (title === "Liste des Décrets") {
      setImageSrc(JuridiqueImage);
    }
    if (title === "Liste des Arrêtés") {
      setImageSrc(JuridiqueImage);
    }
    if (title === "Observatoire") {
      setImageSrc(JuridiqueImage);
    }
    if (title === "Liste des Réformes") {
      setImageSrc(ReformImage);
    }
    if (path.match("/ecosysteme-du-tchad")) {
      setImageSrc(EcosystemTchadImage);
    }
    if (path.match("/mot-du-ministre")) {
      setImageSrc(MinisterImage);
    }
    if (title === "Plateformes") {
      setImageSrc(PlateformeImage);
    }
    if (title === "Biographie") {
      setImageSrc(MinisterImage);
    }
    if (title === "Agenda du ministre") {
      setImageSrc(MinisterImage);
    }
    if (title === "Archives du ministère") {
      setImageSrc(MinisterImage);
    }
    if (title === "Organigramme du ministère") {
      setImageSrc(MinisterImage);
    }
    if (title === "Directions sous tutelles") {
      setImageSrc(MinisterImage);
    }
    if (title === "Plans stratégiques 20-30") {
      setImageSrc(ProjectImage);
    }
    if (title === "Plan d’action 2024") {
      setImageSrc(ProjectImage);
    }
    if (title === "Projets réalisés") {
      setImageSrc(ProjectImage);
    }
    if (title === "Projets en cours de réalisation") {
      setImageSrc(ProjectImage);
    }
    if (title === "Actualités") {
      setImageSrc(ActiualiteImage);
    }
    if (title === "Rapports d’activités") {
      setImageSrc(RapportActiviteImage);
    }
    if (title === "Doucument Cadre") {
      setImageSrc(DocumentationImage);
    }
    if (title === "Opportunités") {
      setImageSrc(OpportunityImage);
    }
    if (title === "Startups") {
      setImageSrc(EconomieNumeriqueImage);
    }
    if (title === "Promotion Economie Numérique") {
      setImageSrc(EconomieNumeriqueImage);
    }
    if (title === "Sensibilisation") {
      setImageSrc(EconomieNumeriqueImage);
    }
    if (title === "Organisations et fonctionnements") {
      setImageSrc(OrganisationFonctionImage);
    }
    if (title === "Formation") {
      setImageSrc(EconomieNumeriqueImage);
    }
    if (title === "Agenda") {
      setImageSrc(AgendaImage);
    }
    if (title === "Adresse du ministère") {
      setImageSrc(ContactImage);
    }
    if (title === "Contacts") {
      setImageSrc(ContactImage);
    }
    if (title === "Objectifs") {
      setImageSrc(AboutUsImage);
    }
    if (title === "Visions") {
      setImageSrc(AboutUsImage);
    }
    if (title === "Missions") {
      setImageSrc(AboutUsImage);
    }
  }, [path, title]);

  const renderSearchInput = () => {
    if (
      path.startsWith("/lois") ||
      path.startsWith("/decrets") ||
      path.startsWith("/arretes") ||
      path.startsWith("/observatoires") ||
      path.startsWith("/projets-realises") ||
      path.startsWith("/projet-encour-de-realisation") ||
      path.startsWith("/actualites") ||
      path.startsWith("/rapport-activites") ||
      path.startsWith("/document-cadre") ||
      path.startsWith("/opportunites") ||
      path.startsWith("/formation")
    ) {
      return <CardObservatory onFilter={onFilter} />;
    } else if (
      path.startsWith("/directions-sous-tutelles") ||
      path.startsWith("/agenda-du-ministre") ||
      path.startsWith("/archive-du-ministere") ||
      path.startsWith("/reformes") ||
      path.startsWith("/platformes") ||
      path.startsWith("/organisation-et-fonctionnement") ||
      path.startsWith("/plan-strategique-2030") ||
      path.startsWith("/plan-action-2024") ||
      path.startsWith("/etat-des-lieux")
    ) {
      return <CardCurrent onFilter={onFilter} />;
    } else if (
      path.startsWith("/start-up") ||
      path.startsWith("/promotion-economique-numerique") ||
      path.startsWith("/sensibilisation") ||
      path.startsWith("/agenda")
    ) {
      return <CardSearchNum onFilter={onFilter} />;
    } else if (
      path.startsWith("/acteurs") ||
      path.startsWith("/entites-sous-tutelles")
    ) {
      return <SeachTwoTherm onFilter={onFilter} />;
    }
  };

  const getSupl_class = () => {
    if (
      path.startsWith("/lois") ||
      path.startsWith("/decrets") ||
      path.startsWith("/arretes") ||
      path.startsWith("/observatoires") ||
      path.startsWith("/projets-realises") ||
      path.startsWith("/projet-encour-de-realisation") ||
      path.startsWith("/actualites") ||
      path.startsWith("/rapport-activites") ||
      path.startsWith("/document-cadre") ||
      path.startsWith("/opportunites") ||
      path.startsWith("/formation")
    ) {
      return "observatory";
    } else{
      return ""
    }
  }

 
  const renderSearchBlock = () => {
    if (
      !(
        path.startsWith("/ecosysteme-du-tchad") ||
        path.startsWith("/mot-du-ministre") ||
        path.startsWith("/organigramme-du-ministere") ||
        path.startsWith("/missions-du-ministere") ||
        path.startsWith("/visions") ||
        path.startsWith("/objectifs") ||
        path.startsWith("/address-ministere") ||
        path.startsWith("/contact-ministere") ||
        path.startsWith("/biographie")
      )
    ) {
      return (
        <div className={`overlay ${getSupl_class()}`}>
          <div className="row overlay-content">
            <div className="col-lg-12">
              <h2>{RenderSearchTitle()}</h2>
              {renderSearchInput()}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <section
      className="current-sup d-flex align-items-center py-11 py-md-5"
      style={{  backgroundImage: `url(${imageSrc})` }}
    >
      <div className="container d-flex align-items-center text-center justify-content-center">
        <div className="col-lg-12">
          <h1>{title}</h1>
        </div>
      </div>
      {onFilter && renderSearchBlock()}
    </section>
  );
};

export default Banner;
