import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { globalProjectData } from "../redux/actions/action";

const ActionPlan2024 = () => {
  let actionPlan = useSelector((state) => state.globalProject);
  const { loading } = useSelector((state) => state.globalProject);
  const dispatch = useDispatch();
  const [actionPlanData, setActionPlanData] = useState();



  let strategiquePlans = actionPlan?.globalProjects?.data;

  // let strategiquePlansDocument = strategiquePlans?.filter(
  //   (item) => item.media.length === 0
  // );

  // let strategiquePlansText = strategiquePlans?.filter(
  //   (item) => item.media.length > 0
  // );

  useEffect(() => {
    if(strategiquePlans){
      setData(strategiquePlans);
    }
  }, [strategiquePlans]); 
  
  const setData = (data) => {
    setActionPlanData(data);
  };

  const onDataFilter = (keyWord, hear) => {
    let filteredText = strategiquePlans;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (hear) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
    }

    setActionPlanData(filteredText);
    // console.log("On research", filteredText);
  }

  React.useEffect(() => {
    dispatch(globalProjectData("plan-action-2024"));
  }, [dispatch]);

  //plan-action-2024
  return (
    <>
      <Navbar />
      <BannerCurrent title="Plan d’action 2024"  onFilter={onDataFilter} />
      <GlobalCard textData={actionPlanData} documentData={actionPlanData} onFilter={onDataFilter} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default ActionPlan2024;
