import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import {
  actualityInfo,
  currentSituationInfo,
  directionUnderTutellInfo,
  economiePromotionInfo,
  globalProjectInfo,
  ministerAgendaInfo,
  ministerArchiveInfo,
  opportunityInfo,
  organismAndFonctionalityInfo,
  reformeInfo,
  repportInfo,
  sensibilisationInfo,
  startupInfo,
  trainingInfo,
} from "../redux/actions/action";
import SingleDetailPage from "../components/SingleDetailsPage";

const SimpleCardDetails = () => {
  const location = useLocation();
  const path = location.pathname;
  let { slug } = useParams();

  let actuality = useSelector((state) => state.globalActuality);
  let opportunity = useSelector((state) => state.opportunity);
  let directionsSousTutelles = useSelector(
    (state) => state.directionUndertutelle
  );
  let organisationAndFonctionality = useSelector(
    (state) => state.organismAndFonctionality
  );
  let ministerAgenda = useSelector((state) => state.ministerAgenda);
  let realiseProject = useSelector((state) => state.globalProject);
  let ministerArchive = useSelector((state) => state.ministerArchive);
  let repport = useSelector((state) => state.repport);
  let currentSituation = useSelector((state) => state.currentSituation);
  let startup = useSelector((state) => state.startup);
  let economiquePromotion = useSelector((state) => state.economiquePromotion);
  let training = useSelector((state) => state.training);
  let sensibilisation = useSelector((state) => state.sensibilisation);
  let reform = useSelector((state) => state.reforme);

  let loading1 = actuality?.loading;
  let loading2 = opportunity?.loading;
  let loading3 = directionsSousTutelles?.loading;
  let loading4 = organisationAndFonctionality?.loading;
  let loading5 = ministerAgenda?.loading;
  let loading6 = ministerArchive?.loading;
  let loading7 = realiseProject?.loading;
  let loading8 = repport?.loading;
  let loading9 = currentSituation?.loading;
  let loading10 = startup?.loading;
  let loading11 = economiquePromotion?.loading;
  let loading12 = training?.loading;
  let loading13 = sensibilisation?.loading;
  let loading14 = reform?.loading;

  const dispatch = useDispatch();

  const [title, setTitle] = useState();
  const [detailData, setDetailData] = useState();

  useEffect(() => {
    if (path.startsWith("/actualites") && actuality) {
      let actualityDetail = actuality?.actuality?.data;
      setDetailData(actualityDetail);
    }

    if (path.startsWith("/opportunites") && opportunity) {
      let opportunityDetail = opportunity?.opportunity?.data;
      setDetailData(opportunityDetail);
    }

    if (
      path.startsWith("/directions-sous-tutelles") &&
      directionsSousTutelles
    ) {
      let directionsSousTutellesDetail =
        directionsSousTutelles?.directionUnderTutel?.data;
      setDetailData(directionsSousTutellesDetail);
    }

    if (
      path.startsWith("/organisation-et-fonctionnement") &&
      organisationAndFonctionality
    ) {
      let organisationAndFonctionalityDetail =
        organisationAndFonctionality?.organisationAndFonctionality?.data;
      setDetailData(organisationAndFonctionalityDetail);
    }

    if (
      path.startsWith("/agenda-du-ministre") &&
      organisationAndFonctionality
    ) {
      let ministerAgendaDetail = ministerAgenda?.ministerAgend?.data;
      setDetailData(ministerAgendaDetail);
    }

    if (path.startsWith("/projets-realises") && realiseProject) {
      let realiseProjectDetail = realiseProject?.globalProject?.data;
      setDetailData(realiseProjectDetail);
    }

    if (path.startsWith("/projet-encour-de-realisation") && realiseProject) {
      let realiseProjectDetail = realiseProject?.globalProject?.data;
      setDetailData(realiseProjectDetail);
    }

    if (path.startsWith("/archive-du-ministere") && ministerArchive) {
      let ministerArchiveDetail = ministerArchive?.ministerArchive?.data;
      setDetailData(ministerArchiveDetail);
    }

    if (path.startsWith("/rapport-activites") && repport) {
      let repportDetail = repport.repport.data;
      setDetailData(repportDetail);
    }

    if (path.startsWith("/etat-des-lieux") && currentSituation) {
      let currentSituationDetail = currentSituation.currentSituation.data;
      setDetailData(currentSituationDetail);
    }

    if (path.startsWith("/start-up") && startup) {
      let startupDetail = startup.startup.data;
      setDetailData(startupDetail);
    }

    if (
      path.startsWith("/promotion-economique-numerique") &&
      economiquePromotion
    ) {
      let economiePromotionDetail = economiquePromotion.economiePromotion.data;
      setDetailData(economiePromotionDetail);
    }

    if (path.startsWith("/formation") && training) {
      let trainingDetail = training.training.data;
      setDetailData(trainingDetail);
    }
    if (path.startsWith("/sensibilisation") && sensibilisation) {
      let sensibilisationDetail = sensibilisation.sensibilisation.data;
      setDetailData(sensibilisationDetail);
    }
    if (path.startsWith("/reformes") && reform) {
      let reformDetail = reform.reforme.data;
      setDetailData(reformDetail);
    }
  }, [
    actuality,
    opportunity,
    directionsSousTutelles,
    organisationAndFonctionality,
    ministerAgenda,
    realiseProject,
    ministerArchive,
    repport,
    currentSituation,
    startup,
    sensibilisation,
    economiquePromotion,
    training,
    reform,
  ]);

  useEffect(() => {
    if (path.startsWith("/actualites")) {
      dispatch(actualityInfo(slug));
      setTitle("Actualité");
    }
    if (path.startsWith("/opportunites")) {
      setTitle("Opportinuté");
      dispatch(opportunityInfo(slug));
    }
    if (path.startsWith("/directions-sous-tutelles")) {
      setTitle("Direction sous tutelle");
      dispatch(directionUnderTutellInfo(slug));
    }
    if (path.startsWith("/organisation-et-fonctionnement")) {
      setTitle("Organisation et fonctionnement");
      dispatch(organismAndFonctionalityInfo(slug));
    }
    if (path.startsWith("/agenda-du-ministre")) {
      setTitle("Agenda du ministre");
      dispatch(ministerAgendaInfo(slug));
    }
    if (path.startsWith("/projets-realises")) {
      setTitle("Project réalisé");
      dispatch(globalProjectInfo(slug));
    }
    if (path.startsWith("/projet-encour-de-realisation")) {
      setTitle("Project en cours de réalisation");
      dispatch(globalProjectInfo(slug));
    }
    if (path.startsWith("/archive-du-ministere")) {
      setTitle("Archive du ministère");
      dispatch(ministerArchiveInfo(slug));
    }
    if (path.startsWith("/rapport-activites")) {
      setTitle("Rapport d'activité");
      dispatch(repportInfo(slug));
    }
    if (path.startsWith("/etat-des-lieux")) {
      setTitle("Etat des lieux");
      dispatch(currentSituationInfo(slug));
    }
    if (path.startsWith("/start-up")) {
      setTitle("Start up");
      dispatch(startupInfo(slug));
    }
    if (path.startsWith("/promotion-economique-numerique")) {
      setTitle("Promotion Economie Numérique");
      dispatch(economiePromotionInfo(slug));
    }
    if (path.startsWith("/formation")) {
      setTitle("Formation");
      dispatch(trainingInfo(slug));
    }
    if (path.startsWith("/sensibilisation")) {
      setTitle("Sensibilisation");
      dispatch(sensibilisationInfo(slug));
    }
    if (path.startsWith("/reformes")) {
      setTitle("reformes");
      dispatch(reformeInfo(slug));
    }
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title={title} />
      <SingleDetailPage
        data={detailData}
        loading={
          loading1 ||
          loading2 ||
          loading3 ||
          loading4 ||
          loading5 ||
          loading6 ||
          loading7 ||
          loading8 ||
          loading9 ||
          loading10 ||
          loading11 ||
          loading12 ||
          loading13 ||
          loading14
        }
      />
      <NewLetter />
      <Footer />
    </>
  );
};

export default SimpleCardDetails;
