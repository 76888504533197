import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import GlobalCard from "../components/GlobalCard";

import { reformeData } from "../redux/actions/action";

const Reform = () => {
  let reform = useSelector((state) => state.reforme);
  const { loading } = useSelector((state) => state.reforme);
  const dispatch = useDispatch();

  const [reformsDocument, setReformsDocument] = useState();
  const [reformsText, setReformsText] = useState();

  let reforms = reform?.reformes?.data;

  useEffect(() => {
    if (reforms) {
      filterData(reforms);
    }
  }, [reforms]);

  const filterData = (data) => {
    const filteredText = data.filter((item) => item.type === "text");
    const filteredDocument = data.filter((item) => item.type === "document");
    setReformsText(filteredText);
    setReformsDocument(filteredDocument);
  };


  const onDataFilter = (keyWord, hear) => {
    let filteredText = reforms.filter((item) => item.type === "text");
    let filteredDocument = reforms.filter((item) => item.type === "document");

    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );

      filteredDocument = filteredDocument?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );

    }

    if (hear) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );

      filteredDocument = filteredDocument?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
    }

    setReformsText(filteredText);
    setReformsDocument(filteredDocument);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(reformeData());
  }, [dispatch]);

  

  return (
    <>
      <Navbar />
      <BannerCurrent title="Liste des Réformes" onFilter={onDataFilter}/>
      <GlobalCard textData={reformsText} documentData={reformsDocument} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Reform;
