import { createSlice } from "@reduxjs/toolkit";

import { opportunityData, opportunityInfo } from "../actions/action";

const opportunitySlice = createSlice({
  name: "opportunities",
  initialState: {
    loading: false,
    opportunity: undefined,
    opportunities: {},
  },
  extraReducers: (builder) => {
    builder.addCase(opportunityData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(opportunityData.fulfilled, (state, action) => {
      state.loading = false;
      state.opportunities = action.payload;
      state.error = "";
    });
    builder.addCase(opportunityData.rejected, (state, action) => {
      state.loading = false;
      state.opportunities = [];
      state.error = action.error.message;
    });

    builder.addCase(opportunityInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(opportunityInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.opportunity = action.payload;
      state.error = "";
    });
    builder.addCase(opportunityInfo.rejected, (state, action) => {
      state.loading = false;
      state.opportunity = undefined;
      state.error = action.error.message;
    });
  },
});

export default opportunitySlice.reducer;
