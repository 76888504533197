import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import ExtraCard from "../components/ExtraCard";

import { documentData } from "../redux/actions/action";

const Documentation = () => {
  let document = useSelector((state) => state.document);
  const dispatch = useDispatch();

  const [documentDatas, setDocumentDatas] = useState();

  let documents = document?.documents?.data;
  let loading = document?.loading;

  console.log("document: ", documents);

  useEffect(() => {
    if (documents) {
      setData(documents);
    }
  }, [documents]);

  const setData = (data) => {
    setDocumentDatas(data);
  };

  const onDataFilter = (doc, keyWord, starthear, endhear) => {
    let filteredText = documents;
    if (keyWord) {
      filteredText = filteredText?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        console.log(timestampT2);
        return timestampT2 >= timestampT1
      }
      );
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter(donnee => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        console.log(timestampT2);
        return timestampT2 <= timestampT1
      }
      );
    }

    setDocumentDatas(filteredText);
    console.log("On research", filteredText);
  }

  useEffect(() => {
    dispatch(documentData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Document Cardre" onFilter={onDataFilter} />
      <ExtraCard data={documentDatas} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Documentation;
