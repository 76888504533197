import React from "react";

import AboutUs from "../pages/AboutUs";
import Author from "../pages/Author";
import ChadEcosystem from "../pages/ChadEcosystem";
import CurrentSituation from "../pages/CurrentSituation";
import Home from "../pages/Home";
import LegalCard from "../pages/LegalCard";
import Platform from "../pages/Platform";
import Reform from "../pages/Reform";
import Law from "../pages/Law";
import Arrete from "../pages/Arrete";
import Decree from "../pages/Decrees";
import Observatory from "../pages/Observatory";
import OrgnaisationAdFonctionality from "../pages/OrganisationAndFonctionality";
import ActionPlan2024 from "../pages/ActionPlan2024";
import InPorgressProject from "../pages/inprogressProject";
import StartUp from "../pages/SatrtUp";
import Repport from "../pages/Repport";
import RealiseProject from "../pages/RealiseProject";
import StrategiquePlan2030 from "../pages/StrategiquePlan2030";
import Actuality from "../pages/Actuality";
import Documentation from "../pages/Documentation";
import Minister from "../pages/Minister";
import Opportunity from "../pages/Opportunity";
import EconomiquePromotion from "../pages/EconomiquePromotion";
import Sensibilisation from "../pages/Sensibilisation";
import Training from "../pages/Training";
import Agenda from "../pages/Agenda";
import Address from "../pages/Address";
import ContactForm from "../pages/ContactForm";
import TunelEntity from "../pages/TutelEntity";
import Actors from "../pages/Actors";
import ActorDetails from "../pages/ActorDetails";
import SimpleCardDetails from "../pages/SimpleCardDetails";

const publicRoutes = [
  {
    path: "/contact-ministere",
    component: <ContactForm />,
  },
  {
    path: "/address-ministere",
    component: <Address />,
  },
  {
    path: "/formation",
    component: <Training />,
  },
  {
    path: "/formation/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/sensibilisation",
    component: <Sensibilisation />,
  },
  {
    path: "/sensibilisation/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/promotion-economique-numerique",
    component: <EconomiquePromotion />,
  },
  {
    path: "/promotion-economique-numerique/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/opportunites",
    component: <Opportunity />,
  },
  {
    path: "/opportunites/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/agenda",
    component: <Agenda />,
  },
  {
    path: "/a-propos",
    component: <AboutUs />,
  },
  {
    path: "/missions-du-ministere",
    component: <AboutUs />,
  },
  {
    path: "/objectifs",
    component: <AboutUs />,
  },
  {
    path: "/actualites",
    component: <Actuality />,
  },
  {
    path: "/actualites/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/document-cadre",
    component: <Documentation />,
  },
  {
    path: "/start-up",
    component: <StartUp />,
  },
  {
    path: "/start-up/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/rapport-activites",
    component: <Repport />,
  },
  {
    path: "/rapport-activites/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/auteur",
    component: <Author />,
  },
  {
    path: "/ecosysteme-du-tchad",
    component: <ChadEcosystem />,
  },
  {
    path: "/etat-des-lieux",
    component: <CurrentSituation />,
  },
  {
    path: "/etat-des-lieux/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/cardre-legal",
    component: <LegalCard />,
  },
  {
    path: "/direction-sous-tutelle",
    component: <LegalCard />,
  },
  {
    path: "/entites-sous-tutelles",
    component: <TunelEntity />,
  },
  {
    path: "/acteurs",
    component: <Actors />,
  },
  {
    path: "/acteurs/:id",
    component: <ActorDetails />,
  },
  {
    path: "/lois",
    component: <Law />,
  },
  {
    path: "/arretes",
    component: <Arrete />,
  },
  {
    path: "/decrets",
    component: <Decree />,
  },
  {
    path: "/observatoires",
    component: <Observatory />,
  },
  {
    path: "/organisation-et-fonctionnement",
    component: <OrgnaisationAdFonctionality />,
  },
  {
    path: "/organisation-et-fonctionnement/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/directions-sous-tutelles",
    component: <Minister />,
  },
  {
    path: "/directions-sous-tutelles/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/organigramme-du-ministere",
    component: <Minister />,
  },
  {
    path: "/agenda-du-ministre",
    component: <Minister />,
  },
  {
    path: "/agenda-du-ministre/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/archive-du-ministere",
    component: <Minister />,
  },
  {
    path: "/archive-du-ministere/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/biographie",
    component: <Minister />,
  },
  {
    path: "/mot-du-ministre",
    component: <Minister />,
  },
  {
    path: "/plan-action-2024",
    component: <ActionPlan2024 />,
  },
  {
    path: "/plan-strategique-2030",
    component: <StrategiquePlan2030 />,
  },
  {
    path: "/projet-encour-de-realisation",
    component: <InPorgressProject />,
  },
  {
    path: "/projet-encour-de-realisation/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/projets-realises",
    component: <RealiseProject />,
  },
  {
    path: "/projets-realises/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/projets/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/platformes",
    component: <Platform />,
  },
  {
    path: "/reformes",
    component: <Reform />,
  },
  {
    path: "/reformes/:slug",
    component: <SimpleCardDetails />,
  },
  {
    path: "/",
    component: <Home />,
  },
];

export { publicRoutes };
