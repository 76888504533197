import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";

import "../App.css";
import "../theme.css";

import Skeleton from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";

const CardTutelle = ({ data, loading }) => {
  const location = useLocation();
  const path = location.pathname;

  const renderContentBlock = (delay, key) => {
    return (
      <div
        key={key}
        style={{ animationDelay: `${delay}s` }}
        className="col-lg-6 mb-4"
      >
        <div className="entity">
          <div className="d-flex align-items-center">
            <div className="">
              <div style={{ width: "100px", height: "100px" }}>
                {
                  <img
                    src={
                      data[key]?.media.length > 0
                        ? data[key]?.media[0]?.original_url
                        : "https://via.placeholder.com/100"
                    }
                    className="img-fluid rounded-circle"
                    alt={data[key]?.sigle}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                }
              </div>
            </div>

            <div className="ml-4">
              <h3>{data[key]?.sigle}</h3>
              <p>{data[key]?.name}</p>
              <Link to={`${path}/${data[key]?.slug}`}>
                <button className="link-fill link-btn">
                  Lire <FaChevronRight />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingBlock = (delay) => {
    return (
      <div style={{ animationDelay: `${delay}s` }} className="container mt-4">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
          <div className="col-lg-6 mb-4">
            <Skeleton height={100} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && renderLoadingBlock(1000)}
      {!loading && (
        <div className="container mt-4">
          <div className="row">
            {data?.length === 0 && (
              <p className="text-center pt-11">
                Aucune donnée disponible pour le moment. Veuillez réessayer plus
              </p>
            )}
            {data?.map((_, index) => {
              return renderContentBlock(0, index);
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default CardTutelle;
