import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Pagination from "./Pagination";
import { RenderButtonTitle } from "../utils/const";
import ProjectImage from "../assets/images/project.png";
import documentImage from "../assets/images/document.png";
import {
  formatSimpleDate,
  formatDate,
  truncate,
  splitList,
} from "../helpers/global_helper";

const SimpleCard = ({ data, page, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10));
    }
  }, [data]);

  const renderPagination = () => {
    if (
      path.startsWith("/etat-des-lieux") ||
      path.startsWith("/reformes") ||
      path.startsWith("/agenda-du-ministre") ||
      path.startsWith("/organisation-et-fonctionnement") ||
      path.startsWith("/plan-strategique-2030") ||
      path.startsWith("/plan-action-2024") ||
      path.startsWith("/projets-realises") ||
      path.startsWith("/projet-encour-de-realisation") ||
      path.startsWith("/actualites") ||
      path.startsWith("/rapport-activites") ||
      path.startsWith("/start-up")
    ) {
      return (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      );
    }
  };

  const renderContentBlock = (delay, key) => {
    return (
      <div
        key={key}
        style={{ animationDelay: `${delay}s` }}
        className="col-lg-4 mb-4 mt-7"
      >
        <div className="project-single">
          <div className="project-box">
            <div className="project-img">
              {loading ? (
                <Skeleton height={200} />
              ) : (
                <img
                  src={
                    splitedData[currentPage - 1][key]?.media?.find((media) =>
                      media.mime_type.startsWith("image/")
                    )?.original_url || path.startsWith("/projets") ? ProjectImage : documentImage
                  }
                  height="100px"
                  className="img-fluid pb-2 img-container"
                  alt="Project"
                />
              )}
            </div>
            <div className="project-title">
              <div className="d-flex align-items-center justify-content-between py-2">
                {loading ? (
                  <Skeleton width={150} />
                ) : (
                  <h6 className="pb-2 d-inline">
                    {path.startsWith("/etat-des-lieux") ||
                    path.startsWith("/reformes") ||
                    path.startsWith("/organisation-et-fonctionnement") ||
                    path.startsWith("/plan-action-2024") ||
                    path.startsWith("/start-up") ? (
                      <>Par: {splitedData[currentPage - 1][key]?.author}</>
                    ) : (
                      formatDate(splitedData[currentPage - 1][key]?.created_at)
                    )}
                  </h6>
                )}
                {loading ? (
                  <Skeleton width={200} />
                ) : (
                  <h6 className="pb-2 d-inline">
                    {path.startsWith("/etat-des-lieux") ||
                    path.startsWith("/reformes") ||
                    path.startsWith("/organisation-et-fonctionnement") ||
                    path.startsWith("/plan-action-2024") ||
                    path.startsWith("/rapport-activites") ? (
                      <>
                        Publié en:{" "}
                        {formatSimpleDate(
                          splitedData[currentPage - 1][key]?.created_at
                        )}
                      </>
                    ) : path.startsWith("/start-up") ? (
                      <>
                        Créé le:{" "}
                        {formatSimpleDate(
                          splitedData[currentPage - 1][key]?.created_at
                        )}
                      </>
                    ) : (
                      formatDate(splitedData[currentPage - 1][key]?.created_at)
                    )}
                  </h6>
                )}
              </div>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                <h3>
                  {truncate(splitedData[currentPage - 1][key]?.title, 25)}
                </h3>
              )}
              <div className="d-flex align-items-center justify-content-between">
                {loading ? (
                  <>
                    <Skeleton width={100} />
                    <Skeleton width={100} />
                  </>
                ) : (
                  <>
                    {path.startsWith("/rapport-activites") && (
                      <div>
                        Secteur:{" "}
                        {splitedData[currentPage - 1][key]?.secteur_activite}
                      </div>
                    )}
                    {path.startsWith("/rapport-activites") && (
                      <div>
                        Période: {splitedData[currentPage - 1][key]?.periode}
                      </div>
                    )}
                  </>
                )}
              </div>
              {loading ? (
                <Skeleton count={2} />
              ) : (
                <p className="py-3">
                  {truncate(splitedData[currentPage - 1][key]?.content, 80)}
                </p>
              )}
              {loading ? (
                <Skeleton width={100} />
              ) : (
                <Link
                  to={`${page || path}/${
                    splitedData[currentPage - 1][key]?.slug
                  }`}
                  className="btn-project"
                >
                  {RenderButtonTitle()} <FaChevronRight />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingBlock = (delay) => {
    return (
      <div
        style={{ animationDelay: `${delay}s` }}
        className="col-lg-4 mb-4 mt-7"
      >
        <div className="project-single">
          <div className="project-box">
            <div className="project-img">
              <Skeleton height={200} />
            </div>
            <div className="project-title">
              <div className="d-flex align-items-center justify-content-between py-2">
                <Skeleton width={150} />
                <Skeleton width={200} />
              </div>
              <Skeleton width={200} />
              <div className="d-flex align-items-center justify-content-between">
                <>
                  <Skeleton width={100} />
                  <Skeleton width={100} />
                </>
              </div>
              <Skeleton count={2} />
              <Skeleton width={100} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && (
        <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
          {renderLoadingBlock(1000)}
          {renderLoadingBlock(1000)}
          {renderLoadingBlock(1000)}
        </div>
      )}
      { !loading && (
        <>
          <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
            {data?.length === 0 && (
              <p className="text-center pt-11">
                Aucune donnée disponible pour le moment. Veuillez réessayer plus
              </p>
            )}
            {splitedData[currentPage - 1]?.map((_, index) => {
              return renderContentBlock(0, index);
            })}
          </div>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}

    </>
  );
};

export default SimpleCard;
