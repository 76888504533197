
import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Tabs from "./Tabs";

import "../App.css";
import "../theme.css";

import SimpleCard from "./SimpleCard";
import ExtraCard from "./ExtraCard";

import "react-loading-skeleton/dist/skeleton.css";

const GlobalCard = ({ documentData, textData, loading }) => {
  const [activeTab, setActiveTab] = useState("texte");
  const [globaltextData, setglobalTextData] = useState();
  const [globaldocumentData, setglobalDocumentData] = useState();

  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  function setData(){
    setglobalTextData(textData)
    setglobalDocumentData(documentData)
  }

  useEffect(()=>{
    setData()
  }, [documentData, textData, activeTab])

  return (
    <section className="card-current">
      <div className="container">
        <Row>
          <div className="col-lg-3 mx-auto mt-7">
            <Tabs activeTab={activeTab} handleTabChange={handleTabChange} />
          </div>
        </Row>

        <Row>
          {activeTab === "texte" &&  (
            <SimpleCard data={globaltextData} activeTab={activeTab} loading={loading} />
          ) 
          }
          {
            activeTab === "document" &&  (
              <Row>
                <ExtraCard data={globaldocumentData} loading={loading}  />
              </Row>
            )
          }
        </Row>
      </div>
    </section>
  );
};

export default GlobalCard;
