import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";
import AgendaCard from "../components/CardAgenda";
import SinglelPage from "../components/SingleDetailsPage";
import Organigrame from "../components/Organigramme";
import CardTutelle from "../components/CardDirTutelle";
import ArchiveCard from "../components/ArchiveCard";

import {
  ministerBiographyData,
  ministerAgendaData,
  directionUnderTutellData,
  ministerArchiveData,
} from "../redux/actions/action";

const Minister = () => {
  let ministerBiography = useSelector((state) => state.ministerBiography);
  let ministerAgenda = useSelector((state) => state.ministerAgenda);
  let ministerArchive = useSelector((state) => state.ministerArchive);

  let directionsSousTutelles = useSelector(
    (state) => state.directionUndertutelle
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const [ministerBiographyDatas, setMinisterBiographyData] = useState();
  const [ministerAgendaDatas, setMinisterAgendaData] = useState();
  const [ministerArchiveDatas, setMinisterArchiveData] = useState();
  const [directionsSousTutellesDatas, setDirectionsSousTutellesData] = useState();

  let ministerBiographyInfo = ministerBiography?.ministerBiographies?.data;
  let bioloading = ministerBiography?.loading
  let ministerAgendaInfo = ministerAgenda?.ministerAgenda?.data;
  let agendaloading = ministerAgenda.loading
  let ministerArchiveInfo = ministerArchive?.ministerArchive?.data;
  let archiveloading = ministerArchive?.loading
  let directionsSousTutellesInfo = directionsSousTutelles?.directionUnderTutell?.data;
  let tutelloading = directionsSousTutelles?.loading
  

  React.useEffect(() => {
    dispatch(ministerBiographyData());
    dispatch(ministerAgendaData());
    dispatch(directionUnderTutellData());
    dispatch(ministerArchiveData());
  }, [dispatch]);

  const path = location.pathname;

  useEffect(() => {
    if (ministerBiographyInfo) {
      setMinisterBiographyData(ministerBiographyInfo);
    }
    if (ministerArchiveInfo) {
      setMinisterArchiveData(ministerArchiveInfo);
    }
    if (ministerAgendaInfo) {
      setMinisterAgendaData(ministerAgendaInfo);
    }
    if (directionsSousTutellesInfo) {
      setDirectionsSousTutellesData(directionsSousTutellesInfo);
    }
  }, [ministerArchiveInfo, ministerBiographyInfo, ministerAgendaInfo, directionsSousTutellesInfo]);

  // const setData = (data) => {
  //   ministerArchiveInfo(data);
  // };

  const onDataFilter = (keyWord, hear) => {

    let filteredText1 = ministerArchiveInfo;
    let filteredText2 = ministerAgendaInfo;
    let filteredText3 = ministerBiographyInfo;
    console.log(filteredText3);
    let filteredText4 = directionsSousTutellesInfo;

    if (keyWord) {
      filteredText1 = filteredText1?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
      filteredText2 = filteredText2?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
      // filteredText3 = filteredText3?.filter(donnee => Object.values(donnee).some(
      //   value =>
      //     typeof value === 'string' &&
      //     value.toLowerCase().includes(keyWord.toLowerCase())
      // )
      // );
      filteredText4 = filteredText4?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(keyWord.toLowerCase())
      )
      );
    }

    if (hear) {
      filteredText1 = filteredText1?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
      filteredText2 = filteredText2?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
      // filteredText3 = filteredText3?.filter(donnee => Object.values(donnee).some(
      //   value =>
      //     typeof value === 'string' &&
      //     value.toLowerCase().includes(hear.toLowerCase())
      // )
      // );
      filteredText4 = filteredText4?.filter(donnee => Object.values(donnee).some(
        value =>
          typeof value === 'string' &&
          value.toLowerCase().includes(hear.toLowerCase())
      )
      );
    }

    setMinisterArchiveData(filteredText1);
    setMinisterAgendaData(filteredText2);
    setMinisterBiographyData(filteredText3);
    setDirectionsSousTutellesData(filteredText4);
    // console.log("On research", filteredText);
  }

  const getTitle = () => {
    if (path.startsWith("/biographie")) {
      return "Biographie";
    } else if (path.startsWith("/mot-du-ministre")) {
      return;
    } else if (path.startsWith("/agenda-du-ministre")) {
      return "Agenda du ministre";
    } else if (path.startsWith("/organigramme-du-ministere")) {
      return "Organigramme du ministère";
    } else if (path.startsWith("/archive-du-ministere")) {
      return "Archives du ministère";
    } else if ("/directions-sous-tutelles") {
      return "Directions sous tutelles";
    }
    return "";
  };

  return (
    <>
      <Navbar />
      <BannerCurrent title={getTitle()} onFilter={onDataFilter} />
      {path.startsWith("/mot-du-ministre") && (
        <SinglelPage data={ministerBiographyDatas} loading={bioloading}/>
      )}
      {path.startsWith("/biographie") && (
        <SinglelPage data={ministerBiographyDatas} loading={bioloading} />
      )}
      {path.startsWith("/agenda-du-ministre") && (
        <AgendaCard data={ministerAgendaDatas} loading={agendaloading} />
      )}
      {path.startsWith("/archive-du-ministere") && (
        <ArchiveCard data={ministerArchiveDatas} loading={archiveloading} />
      )}
      {path.startsWith("/organigramme-du-ministere") && <Organigrame />}
      {path.startsWith("/directions-sous-tutelles") && (
        <CardTutelle data={directionsSousTutellesDatas} loading={tutelloading} />
      )}
      <NewLetter />
      <Footer />
    </>
  );
};

export default Minister;
