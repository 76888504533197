import React, { useState, useEffect } from "react";
import { FaLink } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pagination from "./Pagination";
import { splitList, truncate } from "../helpers/global_helper";
import "../App.css";
import "../theme.css";

const MediumCard = ({ data, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10))
    }
  }, [data]);

  let renderPagination = () => {
    if (
      path.startsWith("/etat-des-lieux") ||
      path.startsWith("/lois") ||
      path.startsWith("/decrets") ||
      path.startsWith("/arretes") ||
      path.startsWith("/observatoires") ||
      path.startsWith("/reformes") ||
      path.startsWith("/platformes") ||
      path.startsWith("/projet-encour-de-realisation")
    ) {
      return <Pagination totalPage={totalPage} currentPage={currentPage} onPageChange={setCurrentPage} />;
    }
  };

  const renderContentBlock = (item, delay, key) => {
    return (
      <div style={{ animationDelay: `${delay}s` }} className="col-lg-4 mb-4" key={key}>
        <div className="platform">
          <div className="grow-content">
            <h2>{loading ? <Skeleton width={200} /> : item.name}</h2>
            <p>{loading ? <Skeleton count={3} /> : truncate(item.description, 150)}</p>
            {loading ? (
              <Skeleton width={150} />
            ) : (
              <a
                className="link"
                href={`https://${item.url}`}
                target="_blank"
              >
                <FaLink /> Aller sur le site
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container py-11">
      <div className="row">
        {loading &&
          Array.from({ length: 6 }).map((_, index) => (
            <div style={{ animationDelay: `${index * 0.1}s` }} className="col-lg-4 mb-4" key={index}>
              <div className="platform">
                <div className="grow-content">
                  <Skeleton height={20} width={200} />
                  <Skeleton count={3} />
                  <Skeleton width={150} />
                </div>
              </div>
            </div>
          ))}
        {!loading &&
          splitedData[currentPage - 1]?.map((item, index) => {
            return renderContentBlock(item, index * 0.1, index);
          })}
      </div>
      {!loading && data?.length > 0 && <div className="row">{renderPagination()}</div>}
    </div>
  );
};

export default MediumCard;
