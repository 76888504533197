import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import { aboutUsData } from "../redux/actions/action";
import AboutUsDetail from "./AboutUsDetail";

const AboutUs = () => {

  let aboutUs = useSelector((state) => state.aboutUs);
  const { loading } = useSelector((state) => state.aboutUs);
  const dispatch = useDispatch();

  let aboutUsDataInfo = aboutUs?.aboutUs?.data;

  useEffect(() => {
    dispatch(aboutUsData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title={"A propos"} />
      <AboutUsDetail data={aboutUsDataInfo} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default AboutUs;
