import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import { tchadEcosystemData } from "../redux/actions/action";

import SinglelPage from "../components/SingleDetailsPage";

const TchadEcosystem = () => {
  let tchadEcosystem = useSelector((state) => state.tchadEcosystem);
  const dispatch = useDispatch();


  let tchadEcosystemInfo = tchadEcosystem?.tcadEcosystem?.data;
  let loading = tchadEcosystem?.loading;


  React.useEffect(() => {
    dispatch(tchadEcosystemData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent />
      <SinglelPage data={tchadEcosystemInfo} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default TchadEcosystem;
