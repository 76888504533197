import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_RECENT_PROJECT_DATA,
  GET_HOME_ACTUALITY_DATA,
  GET_CURRENT_SITUATION_DATA,
  GET_ACTOR_DATA,
  GET_ACTOR_INFO,
  GET_REFORME_DATA,
  GET_PLATEFORME_LIST_DATA,
  GET_AGENDA_LISTE_DATA,
  GET_ORGNIGRAM_DATA,
  GET_MANAGMENT_UNDER_SUPERVISION_DATA,
  GET_ABOUT_US_DATA,
  GET_ENTITE_DATA,
  GET_STRATEGIQUE_PLAN_DATA,
  GET_ACTION_PLAN_DATA,
  GET_REALISE_PROJECT_DATA,
  GET_REPPORT_LIST_DATA,
  GET_DOCUMENT_LIST_DATA,
  GET_OPPORTUNITY_LIST_DATA,
  GET_STARTUP_LIST_DATA,
  GET_PROMOTION_DATA,
  GET_SENSIBILISATION_LIST_DATA,
  GET_FORMATION_LIST_DATA,
  GET_JURI_DOCUMENT_DATA,
  GET_JURI_CATEGORY_DOCUMENT_DATA,
  GET_ORGNISM_AND_FONCTIONALITY_DATA,
  GET_GLOBAL_PROJECT_DATA,
  GET_GLOBAL_ACTUALITY_DATA,
  GET_CHAD_ECO_DATA,
  GET_MINISTER_BIOGRAPHY_DATA,
  GET_MINISTER_AGENDA_DATA,
  GET_MINISTER_ARCHIVE_DATA,
  GET_DIRECTION_UNDER_TUTELLE_DATA,
  GET_PROMOTION_ECONOMIQUE_DATA,
  POST_SUBCRIBE_TO_NEWLETTER,
  GET_ACTUALITY_DETAIL,
  GET_OPPORTUNITY_DETAIL,
  GET_DIRECTION_UNDER_TUTELLE_DETAIL,
  GET_ORGNISM_AND_FONCTIONALITY_DETAIL,
  GET_MINISTER_AGENDA_DETAIL,
  GET_REALISE_PROJECT_DETAIL,
  GET_GLOBAL_PROJECT_DETAIL,
  GET_MINISTER_ARCHIVE_DETAIL,
  GET_REPPORT_DETAIL,
  GET_CURRENT_SITUATION_DETAIL,
  GET_STARTUP_LIST_DETAIL,
  GET_PROMOTION_ECONOMIQUE_DETAIL,
  GET_FORMATION_LIST_DETAIL,
  GET_SENSIBILISATION_LIST_DETAIL,
  GET_REFORME_DETAIL,
} from "../../helpers/url_helper";
import { get, post } from "../../helpers/api_helper";

export const recentProjectData = createAsyncThunk(
  "projects/projectData",
  async () => {
    try {
      const response = await get(GET_RECENT_PROJECT_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Actuality
export const actualityData = createAsyncThunk(
  "actuality/actualityData",
  async () => {
    try {
      const response = await get(GET_HOME_ACTUALITY_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const globalActualityData = createAsyncThunk(
  "globalActuality/globalActualityData",
  async () => {
    try {
      const response = await get(GET_GLOBAL_ACTUALITY_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const actualityInfo = createAsyncThunk(
  "globalActuality/actualityInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_ACTUALITY_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// CurrentSituation
export const currentSituationData = createAsyncThunk(
  "currentSituation/currentSituationData",
  async () => {
    try {
      const response = await get(GET_CURRENT_SITUATION_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const currentSituationInfo = createAsyncThunk(
  "currentSituation/currentSituationInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_CURRENT_SITUATION_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Acteur
export const actorData = createAsyncThunk("actor/actorData", async () => {
  try {
    const response = await get(GET_ACTOR_DATA);
    return response;
  } catch (error) {
    throw error;
  }
});

export const actorInfo = createAsyncThunk("actor/actorInfo", async (id) => {
  try {
    const response = await get(`${GET_ACTOR_INFO}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});

export const reformeData = createAsyncThunk("reforme/reformeData", async () => {
  try {
    const response = await get(GET_REFORME_DATA);
    return response;
  } catch (error) {
    throw error;
  }
});

export const reformeInfo = createAsyncThunk(
  "reforme/reformeInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_REFORME_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const plateformeData = createAsyncThunk(
  "plateforme/plateformeData",
  async () => {
    try {
      const response = await get(GET_PLATEFORME_LIST_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const agendaData = createAsyncThunk("agenda/agendaData", async () => {
  try {
    const response = await get(GET_AGENDA_LISTE_DATA);
    return response;
  } catch (error) {
    throw error;
  }
});

export const organigramData = createAsyncThunk(
  "organigram/organigramData",
  async () => {
    try {
      const response = await get(GET_ORGNIGRAM_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const managmentUnderSupervisionData = createAsyncThunk(
  "managmentUnderSupervision/managmentUnderSupervisionData",
  async () => {
    try {
      const response = await get(GET_MANAGMENT_UNDER_SUPERVISION_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const aboutUsData = createAsyncThunk("aboutUs/aboutUsData", async () => {
  try {
    const response = await get(GET_ABOUT_US_DATA);
    return response;
  } catch (error) {
    throw error;
  }
});

export const entitiesData = createAsyncThunk(
  "entities/entitiesData",
  async () => {
    try {
      const response = await get(GET_ENTITE_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const strategiquePlanData = createAsyncThunk(
  "strategiquePlan/strategiquePlanData",
  async () => {
    try {
      const response = await get(GET_STRATEGIQUE_PLAN_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const actionPlanData = createAsyncThunk(
  "actionPlan/actionPlanData",
  async () => {
    try {
      const response = await get(GET_ACTION_PLAN_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const realiseProjectData = createAsyncThunk(
  "realiseProject/realiseProjectData",
  async () => {
    try {
      const response = await get(GET_REALISE_PROJECT_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const repportData = createAsyncThunk("repport/repportData", async () => {
  try {
    const response = await get(GET_REPPORT_LIST_DATA);
    return response;
  } catch (error) {
    throw error;
  }
});

export const repportInfo = createAsyncThunk(
  "repport/repportInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_REPPORT_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const documentData = createAsyncThunk(
  "document/documentData",
  async () => {
    try {
      const response = await get(GET_DOCUMENT_LIST_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const opportunityData = createAsyncThunk(
  "opportunity/opportunityData",
  async () => {
    try {
      const response = await get(GET_OPPORTUNITY_LIST_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const opportunityInfo = createAsyncThunk(
  "opportunity/opportunityInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_OPPORTUNITY_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const startupData = createAsyncThunk("startup/startupData", async () => {
  try {
    const response = await get(GET_STARTUP_LIST_DATA);
    return response;
  } catch (error) {
    throw error;
  }
});

export const startupInfo = createAsyncThunk(
  "startup/startupInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_STARTUP_LIST_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const promotionData = createAsyncThunk(
  "startup/startupData",
  async () => {
    try {
      const response = await get(GET_PROMOTION_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const sensibilisationData = createAsyncThunk(
  "sensibilisation/sensibilisationData",
  async () => {
    try {
      const response = await get(GET_SENSIBILISATION_LIST_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const sensibilisationInfo = createAsyncThunk(
  "sensibilisation/sensibilisationInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_SENSIBILISATION_LIST_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const trainingData = createAsyncThunk(
  "trainingData/trainingData",
  async () => {
    try {
      const response = await get(GET_FORMATION_LIST_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const trainingInfo = createAsyncThunk(
  "trainingData/trainingInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_FORMATION_LIST_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const juriDocumentData = createAsyncThunk(
  "juriDocument/juridocumenData",
  async () => {
    try {
      const response = await get(GET_JURI_DOCUMENT_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const juriCategoryDocumentData = createAsyncThunk(
  "juriCategoryDocument/juriCategorydocumenData",
  async (slug) => {
    try {
      const response = await get(GET_JURI_CATEGORY_DOCUMENT_DATA, slug);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const organismAndFonctionalityData = createAsyncThunk(
  "organismAndFonctionality/organismAndFonctionalityData",
  async () => {
    try {
      const response = await get(GET_ORGNISM_AND_FONCTIONALITY_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const organismAndFonctionalityInfo = createAsyncThunk(
  "organismAndFonctionality/organismAndFonctionalityInfo",
  async (slug) => {
    try {
      const response = await get(
        `${GET_ORGNISM_AND_FONCTIONALITY_DETAIL}/${slug}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const globalProjectData = createAsyncThunk(
  "globalProject/globalProjectData",
  async (slug) => {
    try {
      const response = await get(GET_GLOBAL_PROJECT_DATA, slug);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const globalProjectInfo = createAsyncThunk(
  "realiseProject/globalProjectInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_GLOBAL_PROJECT_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const tchadEcosystemData = createAsyncThunk(
  "tchadEcosystem/tchadEcosystemData",
  async (slug) => {
    try {
      const response = await get(GET_CHAD_ECO_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const ministerBiographyData = createAsyncThunk(
  "ministerBiography/ministerBiographyData",
  async () => {
    try {
      const response = await get(GET_MINISTER_BIOGRAPHY_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const ministerAgendaData = createAsyncThunk(
  "ministerAgenda/ministerAgendaData",
  async () => {
    try {
      const response = await get(GET_MINISTER_AGENDA_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const ministerAgendaInfo = createAsyncThunk(
  "ministerAgenda/ministerAgendaInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_MINISTER_AGENDA_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const ministerArchiveData = createAsyncThunk(
  "ministerArchive/ministerArchiveData",
  async () => {
    try {
      const response = await get(GET_MINISTER_ARCHIVE_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const ministerArchiveInfo = createAsyncThunk(
  "ministerArchive/ministerArchiveInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_MINISTER_ARCHIVE_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const directionUnderTutellData = createAsyncThunk(
  "directionUnderTutell/directionUnderTutellData",
  async () => {
    try {
      const response = await get(GET_DIRECTION_UNDER_TUTELLE_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const directionUnderTutellInfo = createAsyncThunk(
  "directionUnderTutell/directionUnderTutellInfo",
  async (slug) => {
    try {
      const response = await get(
        `${GET_DIRECTION_UNDER_TUTELLE_DETAIL}/${slug}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const economiePromotionData = createAsyncThunk(
  "economiePromotion/economiePromotionData",
  async () => {
    try {
      const response = await get(GET_PROMOTION_ECONOMIQUE_DATA);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const economiePromotionInfo = createAsyncThunk(
  "economiePromotion/economiePromotionInfo",
  async (slug) => {
    try {
      const response = await get(`${GET_PROMOTION_ECONOMIQUE_DETAIL}/${slug}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const subscribeToNewletter = createAsyncThunk(
  "newsletter/subscribe",
  async (data) => {
    try {
      const response = await post(POST_SUBCRIBE_TO_NEWLETTER, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
