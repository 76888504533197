import { createSlice } from "@reduxjs/toolkit";

import { reformeData, reformeInfo } from "../actions/action";

const reformeSlice = createSlice({
  name: "reformes",
  initialState: {
    loading: false,
    reforme: {},
    reformes: {},
  },
  extraReducers: (builder) => {
    builder.addCase(reformeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reformeData.fulfilled, (state, action) => {
      state.loading = false;
      state.reformes = action.payload;
      state.error = "";
    });
    builder.addCase(reformeData.rejected, (state, action) => {
      state.loading = false;
      state.reformes = [];
      state.error = action.error.message;
    });

    builder.addCase(reformeInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reformeInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.reforme = action.payload;
      state.error = "";
    });
    builder.addCase(reformeInfo.rejected, (state, action) => {
      state.loading = false;
      state.reforme = [];
      state.error = action.error.message;
    });
  },
});

export default reformeSlice.reducer;
