import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

import SingleDetailPage from "./SingleDetailsPage";
import Pagination from "./Pagination";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { formatDate, splitList, truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";

import SensibilityImg from "../assets/images/documentation.png";

const MediumExtraCard = ({ data, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [splitedData, setSplitedData] = useState([]);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.length / 10));
      setSplitedData(splitList(data, 10));
    }
  }, [data]);

  let renderLoadingBlock = () => {
    return (
      <>
        <div className="col-lg-6 mb-4">
          <div className="sensibility">
            <div className="row d-flex align-items-center">
              <div className="col-md-4">
                <Skeleton height={200} />
              </div>
              <div className="col-md-8">
                <Skeleton width={120} />
                <Skeleton width={200} />
                <Skeleton count={3} />
                <Skeleton width={100} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="sensibility">
            <div className="row d-flex align-items-center">
              <div className="col-md-4">
                <Skeleton height={200} />
              </div>
              <div className="col-md-8">
                <Skeleton width={120} />
                <Skeleton width={200} />
                <Skeleton count={3} />
                <Skeleton width={100} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  let renderPagination = () => {
    if (
      path.startsWith("/promotion-economique-numerique") ||
      path.startsWith("/sensibilisation")
    ) {
      return (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      );
    }
  };

  return (
    <div className="container py-11">
      {loading && (
        <div className="row">
          {renderLoadingBlock()}
          {renderLoadingBlock()}
          {renderLoadingBlock()}
        </div>
      )}
      {!loading && (
        <div className="row">
          {data?.length === 0 && (
            <p className="text-center">
              Aucune donnée disponible pour le moment. Veuillez réessayer plus
            </p>
          )}
          {splitedData[currentPage - 1]?.map((item, index) => (
            <>
              <div className="col-lg-6 mb-4" key={index}>
                <div className="sensibility">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                      {loading ? (
                        <Skeleton height={200} />
                      ) : (
                        <img
                          src={SensibilityImg}
                          className="img-fluid"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-md-8">
                      {loading ? (
                        <Skeleton width={120} />
                      ) : (
                        <span>{formatDate(item.created_at)}</span>
                      )}
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <h2>{item.title}</h2>
                      )}
                      {loading ? (
                        <Skeleton count={3} />
                      ) : (
                        <p>{truncate(item.description, 125)}</p>
                      )}

                      {loading ? (
                        <Skeleton width={100} />
                      ) : (
                        <Link
                          to={`${path}/${item.slug}`}
                          className="link-fill"
                          role="button"
                        >
                          Lire <FaChevronRight />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4" key={index}>
                <div className="sensibility">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                      {loading ? (
                        <Skeleton height={200} />
                      ) : (
                        <img
                          src={SensibilityImg}
                          className="img-fluid"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-md-8">
                      {loading ? (
                        <Skeleton width={120} />
                      ) : (
                        <span>{formatDate(item.created_at)}</span>
                      )}
                      {loading ? (
                        <Skeleton width={200} />
                      ) : (
                        <h2>{item.title}</h2>
                      )}
                      {loading ? (
                        <Skeleton count={3} />
                      ) : (
                        <p>{truncate(item.description, 125)}</p>
                      )}

                      {loading ? (
                        <Skeleton width={100} />
                      ) : (
                        <Link
                          to={`${path}/${item.slug}`}
                          className="link-fill"
                          role="button"
                        >
                          Lire <FaChevronRight />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {!loading && (
        <>
          {data?.length > 0 && <div className="row">{renderPagination()}</div>}
        </>
      )}
    </div>
  );
};

export default MediumExtraCard;
