const NavbarItems = [
  {
    label: "Accueil",
    items: [
      {
        label: "Acteurs",
        icon: "pi pi-palette",
        path: "/acteurs",
      },
      {
        label: "Actualités",
        path: "/actualites",
      },
      {
        label: "Opportunités",
        path: "/opportunites",
      },
      {
        label: "Directions sous tutelles",
        path: "/directions-sous-tutelles",
      },
    ],
  },
  {
    label: "Ministère",
    items: [
      {
        label: "Ministre",
        icon: "pi pi-palette",
        items: [
          {
            label: "Biographie",
            path: "/biographie",
          },
          {
            label: "Mot du ministre",
            path: "/mot-du-ministre",
          },
          // {
          //   label: "Ordre du jour du ministre",
          //   path: "/agenda-du-ministre",
          // },
          {
            label: "Organigramme du ministère",
            path: "/organigramme-du-ministere",
          },
        ],
      },
      {
        label: "A propos",
        icon: "pi pi-palette",
        path: "/a-propos"
      },
      {
        label: "Organisations et fonctionnements",
        path: "/organisation-et-fonctionnement",
      },
    ],
  },
  {
    label: "Projets",
    items: [
      {
        label: "Projets réalisés",
        path: "/projets-realises",
      },
      {
        label: "Projets en cours de réalisations",
        path: "/projet-encour-de-realisation",
      },
    ],
  },
  {
    label: "Documentation",
    items: [
      {
        label: "Archives du ministère",
        path: "/archive-du-ministere",
      },
      {
        label: "Rapports d'activités",
        path: "/rapport-activites",
      },
      {
        label: "Document Cadre",
        path: "/document-cadre",
      },
      {
        label: "État des lieux",
        path: "/etat-des-lieux",
      },
      {
        label: "Réglementation",
        icon: "pi pi-palette",
        items: [
          {
            label: "LOIS",
            path: "/lois",
          },
          {
            label: "DECRETS",
            path: "decrets",
          },
          {
            label: "ARETES",
            path: "/arretes",
          },
          {
            label: "OBSERVATOIRES",
            path: "/observatoires",
          },
        ],
      },
      {
        label: "Réformes",
        path: "/reformes",
      },
    ],
  },
  {
    label: "Action",
    items: [
      {
        label: "Startups",
        path: "/start-up",
      },
      {
        label: "Économie numérique",
        path: "/promotion-economique-numerique",
      },
      {
        label: "Sensibilisation",
        path: "/sensibilisation",
      },
      {
        label: "Formation",
        path: "/formation",
      },
    ],
  },
  {
    label: "Contact",
    path: "/contact-ministere",
  },
];

export { NavbarItems };
