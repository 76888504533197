import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import {  actorInfo } from "../redux/actions/action";
import SingleTutelDetail from "../components/SingleTutelDetail";
import { useParams } from "react-router-dom";

const ActorDetails = () => {

  let actor = useSelector((state) => state.actor);
  const dispatch = useDispatch();
  let { id } = useParams();

  const [actorDetail, setActorDetail] = useState();
  
  let loading = actor?.loading;

  

  useEffect(() => {
    if (actor) {
      let actorData = actor?.actor?.data;
      setActorDetail(actorData);
    }
  }, [actor]);


  useEffect(() => {
    dispatch(actorInfo(id));
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Acteur"  />
      <SingleTutelDetail data={actorDetail} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default ActorDetails;
