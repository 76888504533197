import { createSlice } from "@reduxjs/toolkit";

import { directionUnderTutellData, directionUnderTutellInfo } from "../actions/action";

const directionUnderTutellSlice = createSlice({
  name: "ministerAgenda",
  initialState: {
    loading: false,
    directionUnderTutel: undefined,
    directionUnderTutell: {},
  },
  extraReducers: (builder) => {
    builder.addCase(directionUnderTutellData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(directionUnderTutellData.fulfilled, (state, action) => {
      state.loading = false;
      state.directionUnderTutell = action.payload;
      state.error = "";
    });
    builder.addCase(directionUnderTutellData.rejected, (state, action) => {
      state.loading = false;
      state.directionUnderTutell = [];
      state.error = action.error.message;
    });

    builder.addCase(directionUnderTutellInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(directionUnderTutellInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.directionUnderTutel = action.payload;
      state.error = "";
    });
    builder.addCase(directionUnderTutellInfo.rejected, (state, action) => {
      state.loading = false;
      state.directionUnderTutel = [];
      state.error = action.error.message;
    });
  },
});

export default directionUnderTutellSlice.reducer;
