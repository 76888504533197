import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import { globalActualityData } from "../redux/actions/action";
import SimpleCard from "../components/SimpleCard";

const Actuality = () => {
  let actuality = useSelector((state) => state.globalActuality);
  const dispatch = useDispatch();

  const [actualitieDatas, setActualitieDatas] = useState();

  let actualities = actuality?.globalActualities?.data;
  let loading = actuality?.loading;

  useEffect(() => {
    if (actualities) {
      setData(actualities);
    }
  }, [actualities]);

  const setData = (data) => {
    setActualitieDatas(data);
  };

  const onDataFilter = (doc, keyWord, starthear, endhear) => {
    let filteredText = actualities;
    if (keyWord) {
      filteredText = filteredText?.filter((donnee) =>
        Object.values(donnee).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(keyWord.toLowerCase())
        )
      );
    }

    if (starthear) {
      const t1 = new Date(starthear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter((donnee) => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        console.log(timestampT2);
        return timestampT2 >= timestampT1;
      });
    }

    if (endhear) {
      const t1 = new Date(endhear);
      const timestampT1 = t1.getTime();
      console.log(timestampT1);
      filteredText = filteredText?.filter((donnee) => {
        const t2 = new Date(donnee.created_at);
        const timestampT2 = t2.getTime();
        console.log(timestampT2);
        return timestampT2 <= timestampT1;
      });
    }

    setActualitieDatas(filteredText);
    console.log("On research", filteredText);
  };

  useEffect(() => {
    dispatch(globalActualityData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Actualités" onFilter={onDataFilter} />
      <div className="container">
        <SimpleCard data={actualitieDatas} loading={loading}/>
      </div>
      <NewLetter />
      <Footer />
    </>
  );
};

export default Actuality;
