import { createSlice } from "@reduxjs/toolkit";

import { globalProjectData, globalProjectInfo } from "../actions/action";

const globalProjectSlice = createSlice({
  name: "globalProjects",
  initialState: {
    loading: false,
    globalProject: undefined,
    globalProjects: {},
  },
  extraReducers: (builder) => {
    builder.addCase(globalProjectData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(globalProjectData.fulfilled, (state, action) => {
      state.loading = false;
      state.globalProjects = action.payload;
      state.error = "";
    });
    builder.addCase(globalProjectData.rejected, (state, action) => {
      state.loading = false;
      state.globalProjects = undefined;
      state.error = action.error.message;
    });

    builder.addCase(globalProjectInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(globalProjectInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.globalProject = action.payload;
      state.error = "";
    });
    builder.addCase(globalProjectInfo.rejected, (state, action) => {
      state.loading = false;
      state.globalProject = undefined;
      state.error = action.error.message;
    });
  },
});

export default globalProjectSlice.reducer;
