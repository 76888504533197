import React from "react";

import "../App.css";
import "../theme.css";
import Skeleton from "react-loading-skeleton";

const SingleTutelDetail = ({ data, loading }) => {

  return (
    <div className="container pt-6">
      <div className="row ">
        <div className="offset-1 col-lg-9 mx-auto">
          <div className="bloc-detail position-relative">
            <div className=" d-flex align-items-center mb-5">
              <div className="mr-3">
                <div className="circle-img-container">
                  {loading ? (
                    <Skeleton
                      circle
                      height={"100%"}
                      width={"100%"}
                      className="img-fluid rounded-circle"
                    />
                  ) : (
                    <img
                      src={
                        data?.media.length > 0
                          ? data?.media[0]?.original_url
                          : "https://via.placeholder.com/100"
                      }
                      className="img-fluid rounded-circle"
                      alt={data?.sigle}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="ml-3">
                {loading ? (
                  <>
                    <Skeleton height={30} width={100} />
                    <Skeleton height={20} width={150} />
                  </>
                ) : (
                  <>
                    <h2>{data?.sigle}</h2>
                    <p>{data?.name}</p>
                  </>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between py-2 mb-3 ">
              {loading ? (
                <div className="w-50">
                  <Skeleton height={30} width={"100%"} />
                </div>
              ) : (
                <h5>
                  <strong>Directeur Général :</strong>{" "}
                  {data?.dirigant || data?.dirigeant || data?.sigle}
                </h5>
              )}
            </div>
            <div className="text text-break">
              {loading ? (
                <div className="w-100">
                  <Skeleton height={25} width={"100%"} />
                  <br />
                  <Skeleton height={25} width={"100%"} />
                </div>
              ) : (
                <p>
                  <div
                    className="text-start inner-html"
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  />
                  <br />
                  <div
                    className="text-start inner-html"
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTutelDetail;
