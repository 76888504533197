import { createSlice } from "@reduxjs/toolkit";

import { organismAndFonctionalityData, organismAndFonctionalityInfo } from "../actions/action";

const organismAndFonctionalitySlice = createSlice({
  name: "organismAndFonctionalities",
  initialState: {
    loading: false,
    organismAndFonctionality: undefined,
    organismAndFonctionalities: {},
  },
  extraReducers: (builder) => {
    builder.addCase(organismAndFonctionalityData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(organismAndFonctionalityData.fulfilled, (state, action) => {
      state.loading = false;
      state.organismAndFonctionalities = action.payload;
      state.error = "";
    });
    builder.addCase(organismAndFonctionalityData.rejected, (state, action) => {
      state.loading = false;
      state.organismAndFonctionalities = [];
      state.error = action.error.message;
    });

    builder.addCase(organismAndFonctionalityInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(organismAndFonctionalityInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.organismAndFonctionality = action.payload;
      state.error = "";
    });
    builder.addCase(organismAndFonctionalityInfo.rejected, (state, action) => {
      state.loading = false;
      state.organismAndFonctionality = undefined;
      state.error = action.error.message;
    });
  },
});

export default organismAndFonctionalitySlice.reducer;
