import React, { useState } from "react";
import "../App.css";
import "../theme.css";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const CardObservatory = ({onFilter}) => {

  const [keyWord, setKeyWord] = useState("");
  const [document, setDocument] = useState("");
  const [starthear, setStartHear] = useState("");
  const [endhear, setEndHear] = useState("");

  return (
    <Row className="mb-3">
      <Col>
        <Form.Group controlId="formGridSelect">
          <Form.Label>Type de document</Form.Label>
          <Form.Select className="w-100" value={document}
            onChange={(e) => { setDocument(e.target.value) }}>
            <option >Tous les documents</option>
            <option value="1">Documents 1</option>
          </Form.Select>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group controlId="formGridText">
          <Form.Label>Mots clés</Form.Label>
          <Form.Control type="text" placeholder="Saisissez les mots clés" className="w-100" value={keyWord}
            onChange={(e) => { setKeyWord(e.target.value) }} />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group controlId="formGridDate">
          <Form.Label>Du</Form.Label>
          <Form.Control type="date" placeholder="aaa" className="w-100"  value={starthear}
            onChange={(e) => { setStartHear(e.target.value) }}/>
        </Form.Group>
      </Col>
      
      <Col>
        <Form.Group controlId="formGridDate">
          <Form.Label>Au</Form.Label>
          <Form.Control type="date" placeholder="aaa" className="w-100"  value={endhear}
            onChange={(e) => { setEndHear(e.target.value) }}/>
        </Form.Group>
      </Col>

      <Col className="text-center">
        <Form.Group controlId="formGridSubmit">
          <Button type="submit" onClick={()=> onFilter(document, keyWord, starthear, endhear)}>
            Rechercher
          </Button>
        </Form.Group>
      </Col>
    </Row>
  );
}

export default CardObservatory;
