import React from "react";
import { FaChevronRight } from "react-icons/fa";

import { truncate } from "../helpers/global_helper";

import "../App.css";
import "../theme.css";
import { Link, useLocation } from "react-router-dom";

const CardTutelle = ({ data, loading }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="container pt-11">
      {data?.map((item, index) => (
        <div className="row grow" key={index}>
          <h2 className="title">{item?.name}</h2>
          {item?.directions?.map((elemt, index) => (
            <div className="col-lg-4 mb-4" key={index}>
              <div className="header pl-0 pr-0">
                <h3>{elemt?.name}</h3>
                <div className="grow-content">
                  <p className="director">
                    <span>Directeur Général :</span> {elemt?.director_name}
                  </p>
                  <p>{truncate(elemt?.description, 150)}</p>
                  <Link to={`${path}/${elemt?.slug}`} className="link-fill">
                    Lire la plus <FaChevronRight />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardTutelle;
