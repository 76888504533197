import React from "react";
import "../App.css";

import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import drapeau from "../assets/images/Drapeau.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid px-0">
        <div className="row flag m-0">
          <div className="col-lg-12 ">
            <img src={drapeau} height="20" className="w-100" alt="Logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid mx-3">
        {/* <div className="bar"></div> */}

        <div className="row footer-bottom d-flex justify-content-between">
          <div className="col-lg-2 col-sm-6 mb-3">
            <h5 className="footer-item-title">LE SECTEUR</h5>
            <ul className="footer-item  ">
              <li>
                <a href="/reformes">RÉFORMES</a>
              </li>
              <li>
                <a href="/ecosysteme-du-tchad">L’ÉCOSYSTÈME AU TCHAD</a>
              </li>
              <li>
                <a href="/platformes">PLATEFORMES</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3">
            <h5 className="footer-item-title">LA MINISTERE</h5>
            <ul className="footer-item">
              <li>
                <a href="/mot-du-ministre">LA MINISTRE</a>
              </li>
              <li>
                <a href="/missions-du-ministere">MISSION</a>
              </li>
              <li>
                <a href="/organigramme-du-ministere">ORGANIGRAMME</a>
              </li>
              <li>
                <a href="/directions-sous-tutelles">DIRECTIONS</a>
              </li>
              <li>
                <a href="/entites-sous-tutelles"> STRUCTURE SOUS TUTELLES</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3">
            <h5 className="footer-item-title">PROJETS</h5>
            <ul className="footer-item">
              <li>
                <a href="/plan-strategique-2030">PLAN STRATéGIQUE 20-30</a>
              </li>
              <li>
                <a href="/plan-action-2024">PLAN D'ACTION 2024</a>
              </li>
              <li>
                <a href="/projets-realises">PROJETS REALISES</a>
              </li>
              <li>
                <a href="/projet-encour-de-realisation">
                  PROJET EN COURS DE REALISATIONS
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3">
            <h5 className="footer-item-title">PUBLICATIONS</h5>
            <ul className="footer-item">
              <li>
                <a href="/actualites">ACTUALITÉS</a>
              </li>
              <li>
                <a href="/document-cadre">DOCUMENTS</a>
              </li>
              <li>
                <a href="/opportunites">OPPORTUNITÉS</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3">
            <h5 className="footer-item-title">CONTACT</h5>
            <ul className="footer-item">
              <li>
                <a href="#">
                  <FaPhoneAlt /> 001 35XXXXXXXXX
                </a>
              </li>
              <li>
                <a href="#">
                  <HiOutlineMail /> numerique.gouv.td
                </a>
              </li>
              <li>
                <a href="#">
                  <MdLocationOn /> Rue 662, N’Djamena
                </a>
              </li>
              <li>
                <div className="footer-item">
                  <ul className="social-medias ">
                    <li className="social-media">
                      <a
                        href="https://www.facebook.com/MpenTchad?mibextid=ZbWKwL"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookSquare size={30} color="#fff" />
                      </a>
                    </li>
                    <li className="social-media">
                      <a href="#">
                        <FaTwitter size={30} color="#fff" />
                      </a>
                    </li>
                    <li className="social-media">
                      <a href="#">
                        <FaInstagram size={30} color="#fff" />
                      </a>
                    </li>
                    <li className="social-media">
                      <a href="#">
                        <FaLinkedin size={30} color="#fff" />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="bar"></div>
      </div>

      <div className="row boc-icons justify-content-center position-relative">
        <div className="foot-bottom"></div>
        <p className="copyright">Tchad - 2024 - Mentions Légales</p>
      </div>
    </footer>
  );
};

export default Footer;
