import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import NewLetter from "../components/NewLetter";
import BannerCurrent from "../components/BannerCurrent";

import { actorData } from "../redux/actions/action";
import CardTutelle from "../components/CardEntyTutelle";

const Actors = () => {
  let actor = useSelector((state) => state.actor);
  let loading = actor?.loading;
 
  const dispatch = useDispatch();

  const [actorText, setactorText] = useState([]);

  useEffect(() => {
    if (actor) {
      let actorsData = actor?.actors.data;
      setactorText(actorsData);
    }
  }, [actor]);

  const onDataFilter = (keyWord, sigle) => {
    let filteredText = actorText?.filter((item) => item.type === "text");

    if (keyWord) {
      filteredText = filteredText?.filter((donnee) =>
        Object.values(donnee).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(keyWord.toLowerCase())
        )
      );
    }

    if (sigle) {
      filteredText = filteredText?.filter((donnee) =>
        Object.values(donnee).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(sigle.toLowerCase())
        )
      );
    }

    setactorText(filteredText);
  };

  React.useEffect(() => {
    dispatch(actorData());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <BannerCurrent title="Acteurs" onFilter={onDataFilter} />
      <CardTutelle data={actorText} loading={loading} />
      <NewLetter />
      <Footer />
    </>
  );
};

export default Actors;
