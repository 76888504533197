import React from "react";
import "../App.css";
import "../theme.css";

import Organigramme from "../assets/images/organigramme.png";

const Organigrame = () => {
  return (
    <div className="container py-3">
      <div className="row mt-7">
        <div className="organigramme-box">
          <div className="col-lg-12">
            <img src={Organigramme} className="img-fluid w-100" alt="Project" />
          </div>
        </div>
        <div className="legende col-lg-12 mt-7">
          <h2>Légende</h2>
          <div className="cigle">
            <h3>
              CMAI : <span>Comité Ministériel d'Audit Interne;</span>
            </h3>
            <h3>
              CMMR : <span>Comité Ministériel de Maîtrise des Risques;</span>
            </h3>
            <h3>
              CMSIC :
              <span>
                 Commission Ministérielle des Systèmes d'Information et de la
                Connectivité;
              </span>
            </h3>
            <h3>
              C/PIP : <span>Comité « Programme d'Investissement Public »;</span>
            </h3>
            <h3>
              CTPR : <span>Comité Technique de Pilotage des Réformes;</span>
            </h3>
            <h3>
              PRMP : <span>Personne Responsable des Marchés Publics.</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organigrame;
