import React, { useEffect, useState } from "react";

import "../App.css";
import "../theme.css";
import Skeleton from "react-loading-skeleton";

const AboutUsDetail = ({ data, loading }) => {

  const renderLoadingBlock = () => {
    return (
      <div className="bloc-detail d-flex flex-column">
        <div className="w-50">
          <Skeleton height={30} width={100} />
        </div>
        <div className="w-100">
          <Skeleton height={25} width={"100%"} />
          <Skeleton height={25} width={"100%"} />
        </div>
        <br />
        <div className="w-50">
          <Skeleton height={30} width={100} />
        </div>
        <div className="w-100">
          <Skeleton height={25} width={"100%"} />
          <Skeleton height={25} width={"100%"} />
        </div>
        <br />
        <div className="w-50">
          <Skeleton height={30} width={100} />
        </div>
        <div className="w-100">
          <Skeleton height={25} width={"100%"} />
          <Skeleton height={25} width={"100%"} />
        </div>
      </div>
    );
  };

  return (
    <div className="container pt-6">
      <div className="row ">
        <div className="offset-1 col-lg-9 mx-auto">
          {loading ? (
            renderLoadingBlock()
          ) : (
            <div className="bloc-detail position-relative">
              <div className="mb-3">
                <h2 className="mb-5">Mission</h2>
                <div className="text text-break">
                  <p>
                    <div
                      className="text-start inner-html"
                      dangerouslySetInnerHTML={{ __html: data?.mission }}
                    />
                  </p>
                </div>
              </div>

              <div className="mt-2 mb-3">
                <h2 className="mb-5">Vision</h2>
                <div className="text text-break">
                  <p>
                    <div
                      className="text-start inner-html"
                      dangerouslySetInnerHTML={{ __html: data?.vision }}
                    />
                  </p>
                </div>
              </div>

              <div className="mt-2 mb-3">
                <h2 className="mb-5">Objectif</h2>
                <div className="text text-break">
                  <p>
                    <div
                      className="text-start inner-html"
                      dangerouslySetInnerHTML={{ __html: data?.objectif }}
                    />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutUsDetail;
